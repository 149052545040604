<template>
  <div>
    <!-- Table -->
    <b-pagination size="sm" align="fill"
                  v-model="currentPage"
                  :total-rows="getTotalItems"
                  :per-page="perPage"
                  aria-controls="polizas-table"
    ></b-pagination>
    <b-table
      id="facturasTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getResourceList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="['folio_fiscal', 'inversionista']"
      @filtered="onFiltered"
      empty-filtered-text="No se encontraron comprobantes fiscales con el criterio de búsqueda escrito"
    >
      <template #cell(fecha)="row">
          <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ new Date(row.value).toLocaleDateString('es-MX', { year: 'numeric', month: 'numeric', day: 'numeric' }) }}
          </span>
      </template>

      <template #cell(folio_fiscal)="row">
        <filtered-row :value="row.value" :filter="filter"/>
      </template>

      <template #cell(folio_interno)="row">
        <div class="text-sm w-100">
          {{ row.value }}
        </div>
      </template>

      <template #cell(version)="row">
        <div class="text-sm w-100">
          {{ row.value }}
        </div>
      </template>

      <template #cell(importe)="row">
        <div class="text-sm w-100">
          {{ $formatNumber(row.value) }}
        </div>
      </template>

      <template #cell(verificado)="row">
        <div class="text-sm w-100">
          {{ row.value }}
        </div>
      </template>

      <template #cell(autorizado)="row">
        <div class="text-sm w-100">
          {{ row.value }}
        </div>
      </template>

      <template #cell(cancelado)="row">
        <div class="text-sm w-100">
          {{ row.value }}
        </div>
      </template>

      <template #cell(archivos)="row">
        <div class="text-sm w-100">
          <ul>
            <li v-for="archivo in row.item.archivos" v-bind:key="archivo.id">
              <b-link @click="onDownloadFile(archivo)">
                {{ archivo.filename }}
              </b-link>
            </li>
          </ul>
        </div>
      </template>

      <!-- Detalle -->
      <template #cell(show_validaciones)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Validaciones
        </b-button>
      </template>

      <!-- Acciones -->
      <template #row-details="row">
        <factura-inversionista-detalles :row="row"></factura-inversionista-detalles>
      </template>
    </b-table>
    <loading v-else></loading>

    <!--div v-if="isThereData">
      <paginator
        aria-controls="usersTable"
        :disabled="!getAvailableList"
        show-path="userModule/getUsers"
        :resource="getResourceList"
      ></paginator>
    </div-->
    <!-- End Table -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilteredRow from '@/components/Common/Table/FilteredRow'
import FacturaInversionistaDetalles from './FacturaInversionistaDetalles'

const STORE_MODULE = 'facturasInversionistasModule'

const response = () => ({
  error: false,
  message: '',
  errors: {}
})

export default {
  name: 'FacturaInversionistaList',

  components: {
    FilteredRow,
    FacturaInversionistaDetalles
  },

  created () {
    this.submit()
  },

  props: {
    filter: {
      required: true
    }
  },

  data () {
    return {
      selectedId: null,

      isLoading: false,

      response: response(),

      fields: [
        { key: 'fecha', label: 'Fecha' },
        { key: 'folio_fiscal', label: 'Folio Fiscal' },
        { key: 'folio_interno', label: 'Folio Interno' },
        { key: 'version', label: 'Versión' },
        { key: 'importe', label: 'Importe' },
        { key: 'verificado', label: 'Válido' },
        { key: 'autorizado', label: 'Autorizado' },
        { key: 'cancelado', label: 'Cancelado' },
        { key: 'archivos', label: 'Archivos' },
        { key: 'show_validaciones', label: 'Validaciones' }
      ],

      perPage: 10,

      currentPage: 1,

      totalRows: 0
    }
  },

  computed: {
    ...mapGetters(STORE_MODULE, [
      'getResourceList',
      'getAvailableList',
      'isThereData',
      'getResource',
      'getTotalItems'
    ])
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch(`${STORE_MODULE}/getResource`)
      this.totalRows = this.getTotalItems

      if (error) this.$notify({ error, message }, 'Facturas')
    },

    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    },

    async onDownloadFile (archivo) {
      const result = await this.$store.dispatch('archivoModule/download', archivo.id)

      await this.$onDownload(result, archivo.filename)

      this.$notify({ message: `Descarga de archivo ${archivo.filename}` })
    }
  }
}
</script>

<style scoped>

</style>
