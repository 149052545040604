<template>
  <base-view title="Comprobantes" icon="folder">
    <div>
      <check-authorization :requiresAuthorizations="['inversionistas indice facturas']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Mis Comprobantes

            <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
              <!-- Form -->
              <check-authorization
                :requiresAuthorizations="['inversionistas crear facturas']"
                :overrideIf="$store.getters['facturaModule/isEditingResource']"
              >
                <factura-inversionista-form
                  :value="showForm || isEditingResource"
                  @input="showForm = $event"
                />
              </check-authorization>
              <!-- Search -->
              <search-bar
                placeholder="Buscar por fecha, folio fiscal, folio interno, importe o período"
                @filter="(filter) => this.filter = filter"></search-bar>
              <!-- Options -->
              <div class="dropdown no-caret">
                <button
                  class="btn btn-transparent-dark btn-icon dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  <i data-feather="more-vertical"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right animated--fade-in-up">
                  <a class="dropdown-item my-cursor" @click="getUsers(null)">
                    Recargar
                  </a>
                </div>
              </div>
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <factura-inversionista-list @on-got-item="showForm = true" :filter="filter"></factura-inversionista-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import FacturaInversionistaForm from '@/components/Inversionistas/Facturas/FacturaInversionistaForm'
import FacturaInversionistaList from '@/components/Inversionistas/Facturas/FacturaInversionistaList'
import { mapActions, mapGetters } from 'vuex'
import SearchBar from '../../components/Common/Views/SearchBar.vue'

const STORE_MODULE = 'facturasInversionistasModule'

export default {
  name: 'FacturasInversionistasView',

  components: {
    FacturaInversionistaForm,
    FacturaInversionistaList,
    SearchBar
  },

  data () {
    return {
      hideButton: false,
      showForm: false,
      filter: ''
    }
  },

  computed: mapGetters(STORE_MODULE, ['isEditingResource']),

  methods: {
    ...mapActions(STORE_MODULE, ['getResources'])
  }
}
</script>

<style scoped>

</style>
